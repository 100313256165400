import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/prasemap/Login'),
    },
    {
      path: '/page',
      component: () => import('@/views/prasemap/Index'),
      children: [
        {
          name: 'Overview',
          path: 'home',
          component: () => import('@/views/prasemap/Overview'),
        },
        {
          name: 'Visualization',
          path: 'visualization',
          component: () => import('@/views/prasemap/pages/Visualization'),
        },
        {
          name: 'Log',
          path: 'log',
          component: () => import('@/views/prasemap/pages/Log'),
        },
        {
          name: 'Feedback',
          path: 'feedback',
          component: () => import('@/views/prasemap/pages/Feedback'),
        },
        {
          path: '*',
          redirect: 'home',
        },
      ],
    },
  ],
      // children: [
        // Dashboard
        // {
        //   name: 'Dashboard',
        //   path: '',
        //   component: () => import('@/views/dashboard/Dashboard'),
        // },

      //   {
      //     name: 'PRASEMap',
      //     path: '',
      //     component: () => import('@/views/PRASEMap'),
      //   },
      //   // Pages
      //   {
      //     name: 'User Profile',
      //     path: 'pages/user',
      //     component: () => import('@/views/dashboard/pages/UserProfile'),
      //   },
      //   {
      //     name: 'Notifications',
      //     path: 'components/notifications',
      //     component: () => import('@/views/dashboard/component/Notifications'),
      //   },
      //   {
      //     name: 'Icons',
      //     path: 'components/icons',
      //     component: () => import('@/views/dashboard/component/Icons'),
      //   },
      //   {
      //     name: 'Typography',
      //     path: 'components/typography',
      //     component: () => import('@/views/dashboard/component/Typography'),
      //   },
      //   // Tables
      //   {
      //     name: 'Regular Tables',
      //     path: 'tables/regular-tables',
      //     component: () => import('@/views/dashboard/tables/RegularTables'),
      //   },
      //   // Maps
      //   {
      //     name: 'Google Maps',
      //     path: 'maps/google-maps',
      //     component: () => import('@/views/dashboard/maps/GoogleMaps'),
      //   },
      //   // Upgrade
      //   {
      //     name: 'Upgrade',
      //     path: 'upgrade',
      //     component: () => import('@/views/dashboard/Upgrade'),
      //   },
      // ],
    // },
  // ],
})
